import * as React from "react";
import { useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import {
  dataEndpointConfig,
  getDeviceData,
  DeviceProps,
  getIndexByObjectImei,
  getMaxValues,
  getOverviewImeiList,
  getUrlParams,
  maybeParseJson,
} from "../data";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {
  IonDetails,
  IonDataProps,
  IonDeviceDetailProps,
} from "../components/ProjectComponents";
import { StandardTemplate } from "../components/Templates";
import {
  Col,
  Row,
  LoadingScreen,
  Section,
  Headline,
  HeadlineContainer,
} from "../components/GlobalComponents";
import { RedAnimation } from "../components/GlobalComponents/LoadingAnimation";
import { PushToTalk } from "../components/ProjectComponents/PushToTalk/PushToTalk";

const client = new W3CWebSocket(`wss://nodered.vodafone.dev/ws/iot-data`);

const PPT = () => {
  const [view, setView] = useState<string>("overview");
  const [currentDevice, setCurrentDevice] = useState<string | null>(null);
  const [deviceDetail, setDeviceDetail] = useState<IonDeviceDetailProps>({
    data: [],
    latestData: {
      imei: "",
      data: {
        temp: 0,
        humidity: 0,
        batvol: 0,
      },
      timestamp: "",
    },
  });
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [overviewData, setOverviewData] = useState<DeviceProps[]>([]);
  const [loading, setLoading] = useState(true);

  /**
   * Websocket Connection for live data
   */
  useEffect(() => {
    client.onclose = () => {
      alert("Backend connection lost.");
    };
    client.onmessage = (message) => {
      const data = maybeParseJson(message.data);
      if (data.topic && data.topic === "iotData") {
        if (view === "detail" && data.imei.toString() === currentDevice) {
          updateDetail(data);
        }
        updateOverview(data);
      }
    };
  }, [view, overviewData, deviceDetail]);

  /**
   * Initial Data from the DB
   */
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      setLoading(true);
      //this is the detail view after clicking one component
      if (view === "detail" && currentDevice !== "") {
        const query =
          dataEndpointConfig.data.fields +
          `&imei=eq.${currentDevice}` +
          dataEndpointConfig.data.order;

        getDeviceData(instance, account, query).then((data) => {
          const humidities = getMaxValues(data, "humidity", 0, 100);
          const temperatures = getMaxValues(data, "temp", -10, 50);

          setDeviceDetail({
            data: data,
            maxTemp: {
              value: temperatures.highestVal.value,
              timestamp: temperatures.highestVal.timestamp || "",
            },
            minTemp: {
              value: temperatures.lowestVal.value,
              timestamp: temperatures.lowestVal.timestamp || "",
            },
            maxHumidity: {
              value: humidities.highestVal.value,
              timestamp: humidities.highestVal.timestamp || "",
            },
            minHumidity: {
              value: humidities.lowestVal.value,
              timestamp: humidities.lowestVal.timestamp || "",
            },
            latestData: data[0],
          });
          setLoading(false);
        });
      } else {
        const query =
          dataEndpointConfig.latestDeviceData.fields +
          `?project=eq.pushtotalk` +
          dataEndpointConfig.latestDeviceData.order;

        getDeviceData(instance, account, query).then((data) => {
          const imeiList = getOverviewImeiList(data);
          subscribeToNewData(account, imeiList);
          setOverviewData(data);
          setLoading(false);
        });
      }
    }
  }, [account, inProgress, instance, currentDevice, view]);

  const updateOverview = (
    update: Pick<DeviceProps, "imei" | "timestamp"> & {
      data: IonDataProps;
    }
  ) => {
    const newOverviewData = [...overviewData];
    const deviceIndex = getIndexByObjectImei(
      newOverviewData,
      update.imei.toString()
    );
    if (deviceIndex === -1) {
      return;
    }

    newOverviewData[deviceIndex].data = {
      temp: update.data.temp,
      humidity: update.data.humidity,
      batvol: update.data.batvol,
    };
    newOverviewData[deviceIndex].timestamp = update.timestamp;
    setOverviewData(newOverviewData);
  };

  const updateDetail = (update: any) => {
    const newDetailData = { ...deviceDetail };
    const parsedUpdate: DeviceProps = {
      imei: update.imei,
      timestamp: update.timestamp,
      data: update.data,
      location: update.geolocation,
      network: update.network,
    };
    newDetailData.data.unshift(parsedUpdate);

    const humidities = getMaxValues(newDetailData.data, "humidity", 0, 100);
    const temperatures = getMaxValues(newDetailData.data, "temp", -10, 50);

    setDeviceDetail({
      data: newDetailData.data,
      maxTemp: {
        value: temperatures.highestVal.value,
        timestamp: temperatures.highestVal.timestamp || "",
      },
      minTemp: {
        value: temperatures.highestVal.value,
        timestamp: temperatures.lowestVal.timestamp || "",
      },
      maxHumidity: {
        value: humidities.highestVal.value,
        timestamp: humidities.highestVal.timestamp || "",
      },
      minHumidity: {
        value: humidities.lowestVal.value,
        timestamp: humidities.lowestVal.timestamp || "",
      },
      latestData: newDetailData.data[0],
    });
  };

  /**
   * Subscribe to new Data
   */
  const subscribeToNewData = (account: AccountInfo, imeiList: string[]) => {
    client.send(
      JSON.stringify({
        uuid: account.localAccountId,
        imeis: imeiList,
        topic: "subscribe",
      })
    );
  };

  /**
   * Switch between views
   */
  const changeView = (id?: string) => {
    setView(id ? "detail" : "overview");
    if (id) {
      setCurrentDevice(id);
    }
  };

  React.useEffect(() => {
    const params = getUrlParams();
    // IMEI set? => Go to detail view
    if (params && params.id) {
      changeView(params.id);
    }
  }, []);

  return (
    <StandardTemplate>
      <Section>
        {view === "overview" && (
          <>
            <HeadlineContainer color={"monochrome500"}>
              <Row middle={"xs"} center={"xs"}>
                <Col>
                  <Headline text={"PushToTalk"} as={"h2"} styleAs={"h1"} />
                </Col>
              </Row>
            </HeadlineContainer>
            <Row center={"xs"}>
              {overviewData &&
                overviewData.map((device: DeviceProps) => {
                  return (
                    <PushToTalk
                      key={device.imei}
                      device={device}
                      changeView={() => changeView(device.imei)}
                    />
                  );
                })}
            </Row>
          </>
        )}
        {view === "detail" && (
          <IonDetails
            deviceDetail={deviceDetail}
            changeView={() => changeView()}
          />
        )}
      </Section>
      <LoadingScreen show={loading} animation={RedAnimation} />
    </StandardTemplate>
  );
};

export default PPT;
