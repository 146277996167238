import React, { FC } from "react";
import * as S from "./PushToTalk.styles";
import { calculateBatteryPercentage, getBatteryIndicator } from "../../../data";
import moment from "moment";
import {
  Headline,
  RectangularButton,
  Col,
  Subline,
} from "../../GlobalComponents";
import { PttBatteryDesign } from "./BatterieDesign/PttBatteryDesign";

export const PushToTalk: FC = ({ device, changeView }) => {
  const batteryPercentage = calculateBatteryPercentage(device.data?.batvol);

  return (
    <Col xs={6} md={4} lg={3} key={352656101015223}>
      <S.CardContainer background={"bgPanel100"}>
        <Headline text={device.name} align={"center"} styleAs="h3" as="h2" />
        <Subline
          text={`Last update: ${moment(device.timestamp).format(
            "YYYY-MM-DD HH:mm:ss"
          )}`}
        />
        &nbsp;
        <PttBatteryDesign fillLevel={batteryPercentage} />
        <RectangularButton
          label={"Details"}
          variant={"primary"}
          fullWidth
          onClick={() => changeView()}
        />
      </S.CardContainer>
    </Col>
  );
};
