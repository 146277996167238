import React, { FC } from "react";
import * as S from "./PttBatteryDesign.styles";
import { batterieDesignProps } from "./PttBatteryDesign.types";
import { Icon } from "../../../GlobalComponents";
export const PttBatteryDesign: FC<batterieDesignProps> = ({ fillLevel }) => {
  return (
    <S.bigBatteryContainer>
      <S.row>
        <S.batteryDesign>
          <S.headBattery />
          <S.boltIcon>
            &nbsp;
            <Icon as={"Bolt"} />
          </S.boltIcon>
          <S.barBattery fillLevel={fillLevel}>
            <S.bubbles>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
              <S.bubble fillLevel={fillLevel}></S.bubble>
            </S.bubbles>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              className="gooeySvg"
            >
              <defs>
                <filter id="gooey">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="5"
                    result="blur"
                  ></feGaussianBlur>
                  <feColorMatrix
                    in="blur"
                    type="matrix"
                    values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 19 -9"
                    result="highContrastGraphic"
                  ></feColorMatrix>
                  <feComposite
                    in="SourceGraphic"
                    in2="highContrastGraphic"
                    operator="atop"
                  ></feComposite>
                </filter>
              </defs>
            </svg>
          </S.barBattery>
        </S.batteryDesign>
        <S.batteryLevelText>{fillLevel}%</S.batteryLevelText>
      </S.row>
    </S.bigBatteryContainer>
  );
};
