import styled from "styled-components";
import { RectangularButton } from "../../GlobalComponents/RectangularButton/RectangularButton.styles";
import { CardContainer as StyledCardContainer } from "../../GlobalComponents/CardContainer";
export const CardContainer = styled(StyledCardContainer)`
  ${RectangularButton} {
    margin-top: 30px;
  }
`;
export const batterieDesign = styled("div")`
  width: 79px;
  height: 150px;
  border: 3px solid #e60000;
  border-radius: 5px;
`;
export const batterieLevelText = styled("h1")`
  font-weight: bold;
  margin: auto;
  font-size: 30pt;
  text-align: center;
`;
