import styled from "styled-components";
import { Bolt } from "../../../../icons/system-icons/src";
import { Gradients } from "../../../../theme/colors";
import { fontSize, spacing } from "../../../../theme";
type BatteryDesignProps = {
  fillLevel: number;
};

export const bigBatteryContainer = styled("div")`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-end;
`;
export const batteryDesign = styled("div")`
  width: 80px;
  height: 125px;
  border: 3px solid ${({ theme }) => theme.color.primary100};
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2px 0 0;
`;
export const headBattery = styled("div")`
  width: 50%;
  height: 15px;
  border-top: 3px solid ${({ theme }) => theme.color.primary100};
  border-left: 3px solid ${({ theme }) => theme.color.primary100};
  border-right: 3px solid ${({ theme }) => theme.color.primary100};
  border-radius: 5px 5px 0 0;
  position: absolute;
  top: -15px;
  background-color: ${({ theme }) => theme.color.bgPanel100};
  display: flex;
`;
export const boltIcon = styled("div")`
  ${fontSize("l", "h1")};
  position: absolute;
  z-index: 1;
  svg g {
    stroke: ${({ theme }) => theme.color.primary100};
    stroke-width: ${spacing(3)};
  }
`;
export const row = styled("div")`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
`;
export const barBattery = styled.div<BatteryDesignProps>`
  ${({ fillLevel, theme }) =>
    fillLevel < 25
      ? theme.color.gradient.gradient_25porcent
      : fillLevel < 50
      ? theme.color.gradient.gradient_50porcent
      : fillLevel < 75
      ? theme.color.gradient.gradient_75porcent
      : theme.color.gradient.gradient_100porcent};
  border-radius: 0;
  height: ${({ fillLevel }) => Number(fillLevel)}%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  filter: url(#gooey);
`;
export const batteryLevelText = styled("h1")`
  font-weight: bold;
  margin: auto;
  font-size: 30pt;
  text-align: center;
`;
export const bubbles = styled("span")`
  position: absolute;
  top: -5px;
  left: 0;
  bottom: 0;
  right: 0;
`;
export const bubble = styled.span<BatteryDesignProps>`
  background: ${({ fillLevel, theme }) =>
    fillLevel == 0
      ? "transparent"
      : fillLevel < 25
      ? theme.color.gradient_25LastColor
      : fillLevel < 50
      ? theme.color.gradient_50LastColor
      : fillLevel < 75
      ? theme.color.gradient_75LastColor
      : theme.color.gradient_100LastColor};
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: -1;
  :first-child {
    left: 48%;
    width: 16px;
    height: 16px;
    animation: move-1 3.02s infinite;
    animation-delay: 0.2s;
  }

  :nth-child(2) {
    left: 49%;
    width: 16px;
    height: 16px;
    animation: move-2 3.04s infinite;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    left: 12%;
    width: 16px;
    height: 16px;
    animation: move-3 3.06s infinite;
    animation-delay: 0.6s;
  }
  :nth-child(4) {
    left: 7%;
    width: 16px;
    height: 16px;
    animation: move-4 3.08s infinite;
    animation-delay: 0.8s;
  }
  :nth-child(5) {
    left: 16%;
    width: 16px;
    height: 16px;
    animation: move-5 3.1s infinite;
    animation-delay: 1s;
  }
  :nth-child(6) {
    left: 19%;
    width: 16px;
    height: 16px;
    animation: move-6 3.12s infinite;
    animation-delay: 1.2s;
  }
  :nth-child(7) {
    left: 82%;
    width: 16px;
    height: 16px;
    animation: move-7 3.14s infinite;
    animation-delay: 1.4s;
  }
  :nth-child(8) {
    left: 29%;
    width: 16px;
    height: 16px;
    animation: move-8 3.16s infinite;
    animation-delay: 1.6s;
  }
  :nth-child(9) {
    left: 6%;
    width: 16px;
    height: 16px;
    animation: move-9 3.18s infinite;
    animation-delay: 1.8s;
  }
  :nth-child(10) {
    left: 15%;
    width: 16px;
    height: 16px;
    animation: move-10 3.2s infinite;
    animation-delay: 2s;
  }
  @keyframes move-1 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-64px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }
  @keyframes move-2 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-88px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }
  @keyframes move-3 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-58px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }
  @keyframes move-4 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-128px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }

  @keyframes move-5 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-70px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }

  @keyframes move-6 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-76px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }

  @keyframes move-7 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-52px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }

  @keyframes move-8 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-55px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }

  @keyframes move-9 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-84px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }

  @keyframes move-10 {
    0% {
      transform: translate(0);
    }
    99% {
      transform: translateY(-124px);
    }
    to {
      transform: translate(0);
      opacity: 0;
    }
  }
`;
